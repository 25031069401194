import React, { Fragment,useState } from "react";
import "../App.css";
import Navigation from "./navigation";
import { Link } from "react-router-dom";
import Footer from "./footer";
import "../css/index.css";
import TestimonialCarousel from "./TestimonialCarousel";
import IndexBirthday from "./indexbirthday";
import IndexWedding from "./indexwedding";
import FAQ from "./Faq";
import IndexbabyShower from "./indexbabyshower";
import IndexFun from "./indexfun";
import {birthdaypackages} from '../data/birthdaydata'

function Index() {
  const [currentPage, setCurrentPage] = useState(1);
  const packagesPerPage = 4; // Number of packages per page

  // Calculate total pages
  const totalPages = Math.ceil(birthdaypackages.length / packagesPerPage);

  // Get current packages for the page
  const indexOfLastPackage = currentPage * packagesPerPage;
  const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
  const currentPackages = birthdaypackages.slice(indexOfFirstPackage, indexOfLastPackage);
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));

  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  };
  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <Fragment>
      <div>
        <header style={{ height: "93px" }}>
          <Navigation />
        </header>
        <main>
          <a
            href="https://wa.me/+917338011713"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          <a
            href="tel:${+917338011713}"
            className="phone_float"
            rel="noopener noreferrer"
          >
            <i class="fa fa-phone phone-icon"></i>
          </a>

          <div className="slider-area position-relative">
            <div className="slider-active">
              <div className="single-slider slider-height d-flex align-items-center">
                <div className="container" style={{ marginTop: "200px" }}>
                  <div className="row main_content">
                    <div className="col-xl-8 col-lg-8 col-md-9 col-sm-10">
                      <div className="hero__caption">
                        <h5>Committed to celebrate!</h5>
                        <h1
                          data-animation="fadeInLeft"
                          data-delay=".5s"
                          className="header-line"
                        >
                          The Best Balloon Decorators in the City
                        </h1>
                        <div className="slider-btns main_content_download">
                          <Link
                            to="/packages"
                            className="btn hero-btn hero_btn_main"
                            data-animation="fadeInLeft"
                            data-delay="1.0s"
                            onClick={{ scrollToTop }}
                          >
                            Check Packages
                          </Link>

                          <p
                            className="video-cap d-none d-sm-blcok"
                            data-animation="fadeInRight"
                            data-delay="1.0s"
                          >
                            Story Vidoe
                            <br /> Watch
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="single-slider slider-height d-flex align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-9 col-sm-10">
                      <div className="hero__caption">
                        <span
                          data-animation="fadeInLeft"
                          data-delay=".1s"
                        >
                          Committed to success
                        </span>
                        <h1
                          data-animation="fadeInLeft"
                          data-delay=".5s"
                        >
                          Digital Conference For Designers
                        </h1>
                        <div className="slider-btns">
                          <a
                            className="btn hero-btn"
                            data-animation="fadeInLeft"
                            data-delay="1.0s"
                            href="industries.html"
                          >
                            Download
                          </a>
                          <a
                            className="popup-video video-btn"
                            data-animation="fadeInRight"
                            data-delay="1.0s"
                            href="https://www.youtube.com/watch?v=up68UAfH0d0"
                          >
                            <i className="fas fa-play" />
                          </a>
                          <p
                            className="video-cap d-none d-sm-blcok"
                            data-animation="fadeInRight"
                            data-delay="1.0s"
                          >
                            Story Vidoe
                            <br />
                            {' '}Watch
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <section className="about-low-area section-padding2 section-padding2-about">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="about-caption mb-50">
                    <div className="section-tittle mb-35">
                      <h2>
                        Transforming Spaces with Stunning Balloon Decor for
                        Every Occasion.
                      </h2>
                    </div>
                    <p>
                      At Balloon Bliss Events, our mission is to transform
                      ordinary spaces into extraordinary experiences through
                      innovative and stunning balloon decor. We specialize in
                      creating custom balloon designs for all types of events,
                      from intimate gatherings to grand celebrations.
                    </p>
                    <p>
                      Our goal is to bring joy, color, and creativity to every
                      occasion, ensuring unforgettable memories for our clients.
                    </p>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-6 col-md-12 col-sm-6 col-xs-10 d-flex justify-content-center">
                      <div className="single-caption mb-20">
                        <div className="caption-icon">
                          <span className="flaticon-communications-1" />
                        </div>
                        <div className="caption">
                          <h5>Services</h5>
                          <p>Bangalore, Karnataka</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link className="btn bttn mt-40" to="tel:${+917338011713}">
                    Contact Us{" "}
                    <i
                      class="fa fa-phone phone-icon-3"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </Link>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="about-img ">
                    <div className="about-font-img d-none d-lg-block">
                      <img
                        style={{ width: "100%" }}
                        alt=""
                        src="assets/img/adobe2.jpeg"
                      />
                    </div>
                    <div className="about-back-img ">
                      <img
                        style={{ width: "100%" }}
                        alt=""
                        src="assets/img/adobe1.jpeg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          
          <section
            className="team-area pt-180 pb-100 section-bg"
            style={{
              backgroundColor: "#e6005c",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-9">
                  <div className="section-tittle section-tittle2 mb-50">
                    <h2>Explore Our Affordable Packages</h2>
                    <p>
                      Discover the perfect package tailored to your needs.
                      Whether it's for a special occasion or a corporate event,
                      explore our wide range of customizable options designed to
                      make your event unforgettable.
                    </p>
                    <Link
                      className="btn white-btn mt-30"
                      onClick={{ scrollToTop }}
                      style={{ borderRadius: "45px", padding: "28px 30px" }}
                      to="/packages"
                    >
                      Explore Packages
                    </Link>
                    <Link
                      className="btn white-btn bttn mt-30 ml-1"
                      style={{ borderRadius: "45px", padding: "20px 30px" }}
                      to="tel:${+917338011713}"
                    >
                      Talk to us{" "}
                      <i
                        class="fa fa-phone phone-icon-3"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10">
                  <div className="single-team mb-30">
                    <div className="team-img">
                      <img
                        alt=""
                        src="assets/event/wedding/wb2.jpg"
                        style={{ height: "240px" }}
                      />
                      <ul className="team-social">
                        <li>
                          <a
                            href="https://wa.me/+917338011713"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-whatsapp whatsapp-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:${+917338011713}"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-phone phone-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/packages" rel="noopener noreferrer">
                            <i class="fa fa-compass phone-icon-1"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-caption">
                      <h3>
                        <a href="#">Wedding Reception Decor</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10">
                  <div className="single-team mb-30">
                    <div className="team-img">
                      <img
                        alt=""
                        src="assets/event/packages/babys.jpg"
                        style={{ height: "280px" }}
                      />
                      <ul className="team-social">
                        <li>
                          <a
                            href="https://wa.me/+917338011713"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-whatsapp whatsapp-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:${+917338011713}"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-phone phone-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/packages" rel="noopener noreferrer">
                            <i class="fa fa-compass phone-icon-1"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-caption">
                      <h3>
                        <a href="#">Flower Decorators</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10">
                  <div className="single-team mb-30">
                    <div className="team-img">
                      <img
                        alt=""
                        src="assets/event/Birthday/bb2.jpg"
                        style={{ height: "280px" }}
                      />
                      <ul className="team-social">
                        <li>
                          <a
                            href="https://wa.me/+917338011713"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-whatsapp whatsapp-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:${+917338011713}"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-phone phone-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/packages" rel="noopener noreferrer">
                            <i class="fa fa-compass phone-icon-1"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-caption">
                      <h3>
                        <a href="#">Birthday</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10">
                  <div className="single-team mb-30">
                    <div className="team-img">
                      <img
                        alt=""
                        src="assets/event/wedding/wb3.jpg"
                        style={{ height: "240px" }}
                      />
                      <ul className="team-social">
                        <li>
                          <a
                            href="https://wa.me/+917338011713"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-whatsapp whatsapp-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:${+917338011713}"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-phone phone-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/packages" rel="noopener noreferrer">
                            <i class="fa fa-compass phone-icon-1"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-caption">
                      <h3>
                        <a href="#">Cermony</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-10">
                  <div className="single-team mb-30">
                    <div className="team-img">
                      <img
                        alt=""
                        src="assets/event/packages/fun.jpg"
                        style={{ height: "240px" }}
                      />
                      <ul className="team-social">
                        <li>
                          <a
                            href="https://wa.me/+917338011713"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-whatsapp whatsapp-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:${+917338011713}"
                            rel="noopener noreferrer"
                          >
                            <i class="fa fa-phone phone-icon-1"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/packages" rel="noopener noreferrer">
                            <i class="fa fa-compass phone-icon-1"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="team-caption">
                      <h3>
                        <a href="#">Fun and Extra Activities</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="accordion fix section-padding20">
            <div>
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-12 col-md-6">
                  <div className="section-tittle text-center">
                    <h2>We’ll Make Your Next Celebration Very Special!</h2>
                    <p>
                      our dedicated team ensures that every detail is perfect.
                      Whether it's a birthday, wedding, baby shower, or any
                      other special event, we bring your vision to life with
                      elegance and flair
                    </p>
                  </div>
                </div>
              </div>
              <IndexBirthday />
              <IndexWedding />
              <IndexbabyShower />
              {/* <IndexFun /> */}
            </div>
          </section>
          <section
            className="work-company section-padding30"
            style={{ background: "#2e0e8c" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-8">
                  {/* Section Tittle */}
                  <div className="section-tittle section-tittle2 mb-50">
                    <h2>Our Top Decorations</h2>
                    <p>
                    Check out our previous projects to see our expertise in classic décor ideas!
                    </p>
                    <Link
                      className="btn white-btn mt-30"
                      onClick={{ scrollToTop }}
                      style={{ borderRadius: "45px", padding: "28px 30px" }}
                      to="/gallery"
                    >
                      Explore Gallery
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="logo-area">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/BabyShower/bs4.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/Birthday/bb1.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/Birthday/bb20.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/wedding/wb3.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/wedding/wb6.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/Birthday/bb9.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/Birthday/bb11.jpg"
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/Birthday/bb10.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img style={{ width: "110%" }}
                            src="assets/event/BabyShower/bs10.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
{/* 
          

          <div
            className="gallery-area fix"
            style={{ backgroundColor: "bisque" }}
          >
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-6">
                <div className="section-tittle text-center">
                  <h2>Gallery</h2>
                  <p>
                    Take a look at our past work to appreciate our expertise
                    with classic décor ideas!
                  </p>

                </div>
              </div>
            </div>
            <div className="container-fluid p-0">
              <div className="row no-gutters container-fluid p-0">
                <div className="gutter1">
                  <img
                    style={{ height: "90%", width: "50%" }}
                    src="assets/event/Birthday/bb1.jpg"
                    alt="img"
                  ></img>
                  <img
                    style={{ height: "90%", width: "40%" }}
                    src="assets/event/BabyShower/bs4.jpg"
                    alt="img"
                  ></img>
                </div>
                <div className="gutter1">
                  <img
                    style={{ height: "80%", width: "50%" }}
                    src="assets/event/Birthday/bb4.jpg"
                    alt="img"
                  ></img>
                  <img
                    style={{ height: "80%", width: "20%" }}
                    src="assets/event/Birthday/bb3.jpg"
                    alt="img"
                  ></img>
                  <img
                    style={{ height: "80%", width: "20%" }}
                    src="assets/event/Birthday/bb5.jpg"
                    alt="img"
                  ></img>
                </div>
                <div className="gutter1">
                  <img
                    style={{ height: "80%", width: "60%" }}
                    src="assets/event/Wedding/wb1.jpg"
                    alt="img"
                  ></img>
                  <img
                    style={{ height: "80%", width: "20%" }}
                    src="assets/event/Birthday/bb6.jpg"
                    alt="img"
                  ></img>
                </div>
              </div>
              <Link
                className="btn white-btn  mb-10"
                style={{ border: "1px solid" }}
                to="/gallery"
                onClick={scrollToTop}
              >
                View More
              </Link>
            </div>
          </div> */}
          {/* <section className="pricing-card-area section-padding2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <div className="section-tittle text-center mb-100">
                    <h2>View Detailed Pricing</h2>
                    <p>
                      There arge many variations ohf passages of sorem gp
                      ilable, but the majority have ssorem gp iluffe.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
                  <div className="single-card active text-center mb-30">
                    <div className="card-top">
                      <span>Event Packages</span>
                      <h4>Starts from Rs.1000</h4>
                    </div>
                    <div className="card-bottom">
                      <ul>
                        <li>Birthday Parties</li>
                        <li>Weddings</li>
                        <li>Baby Showers</li>
                        <li>Naming Ceremonies</li>
                        <li>Corporate Events</li>
                      </ul>
                      <a className="black-btn" href="/packages">
                        View Packages
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
                  <div className="single-card text-center mb-30">
                    <div className="card-top">
                      <span>Flower Decorations</span>
                      <h4>Starts from Rs.2000</h4>
                    </div>
                    <div className="card-bottom">
                      <ul>
                        <li>Wedding Decorations</li>
                        <li>Birthday Decor</li>
                        <li>Corporate Event Decor</li>
                        <li>24/7 Support</li>
                      </ul>
                      <a className="black-btn" href="/services">
                        View Services
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
                  <div className="single-card active text-center mb-30">
                    <div className="card-top">
                      <span>Fun and Entertainment</span>
                      <h4>Starts from Rs.500</h4>
                    </div>
                    <div className="card-bottom">
                      <ul>
                        <li>DJ and Live Music</li>
                        <li>Games and Activities</li>
                        <li>Photo Booths</li>
                        <li>Party Favors</li>
                      </ul>
                      <a className="black-btn" href="/fun-entertainment">
                        Explore Entertainment
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <section
            className="work-company section-padding30"
            style={{
              background: "#e6005c",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-8">
                  <div className="section-tittle section-tittle2 mb-50">
                    <h2>Our Fun Activities</h2>
                    <p>
                      There are countless ways to add fun to your event with our
                      engaging activities. From balloon twisting workshops to
                      exciting balloon popping games, we offer a variety of
                      options that guarantee smiles and laughter for everyone
                      involved
                    </p>
                    <Link
                      className="btn white-btn mt-30 mr-10"
                      to="tel:${+917338011713}"
                    >
                      Call us
                    </Link>
                    <Link
                      className="btn white-btn mt-30 ml-10"
                      to="/Fun&Entertainment"
                      onClick={scrollToTop}
                    >
                      Explore Fun Adventures
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="logo-area">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-logo mb-30">
                          <img
                            className="funimg"
                            alt=""
                            src="assets/event/packages/fun.jpg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <section className="home-blog-area section-padding30">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-8">
                  <div className="section-tittle text-center mb-50">
                    <h2>News From Blog</h2>
                    <p>
                      There arge many variations ohf passages of sorem gp
                      ilable, but the majority have ssorem gp iluffe.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="home-blog-single mb-30">
                    <div className="blog-img-cap">
                      <div className="blog-img">
                        <img alt="" src="assets/img/gallery/home-blog1.png" />
                        <div className="blog-date text-center">
                          <span>24</span>
                          <p>Now</p>
                        </div>
                      </div>
                      <div className="blog-cap">
                        <p>| Physics</p>
                        <h3>
                          <a href="blog_details.html">
                            Footprints in Time is perfect House in Kurashiki
                          </a>
                        </h3>
                        <a className="more-btn" href="blog_details.html">
                          Read more »
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="home-blog-single mb-30">
                    <div className="blog-img-cap">
                      <div className="blog-img">
                        <img alt="" src="assets/img/gallery/home-blog2.png" />
                        <div className="blog-date text-center">
                          <span>24</span>
                          <p>Now</p>
                        </div>
                      </div>
                      <div className="blog-cap">
                        <p>| Physics</p>
                        <h3>
                          <a href="blog_details.html">
                            Footprints in Time is perfect House in Kurashiki
                          </a>
                        </h3>
                        <a className="more-btn" href="blog_details.html">
                          Read more »
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <TestimonialCarousel />
          <FAQ />
        </main>
        <footer>
          <Footer />
        </footer>
        <div id="back-top">
          <a href="#" title="Go to Top">
            {" "}
            <i className="fas fa-level-up-alt" />
          </a>
        </div>
      </div><style jsx>{`
        .pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }

        .pagination-button {
          background-color: #e6005c;
          color: white;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          margin: 0 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }

        .pagination-button:hover {
          background-color: white;
          color: #e6005c;
        }

        .pagination-button:disabled {
          background-color: #c0c0c0;
          cursor: not-allowed;
        }

        .pagination-info {
          margin: 0 15px;
          font-size: 16px;
        }
      `}</style>
    </Fragment>
  );
}
export default Index;
