import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import "../css/navigation.css"

const Navigation = () => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const closeSubmenu = () => {
        setIsSubmenuOpen(false);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <div>
            <nav id="menu" className="navbar navbar-default navbar-fixed-top">
                <div className="container">
                    <div className="navbar-header navbar-sandwich">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                        >
                            {" "}
                            <span className="sr-only">Toggle Home</span>{" "}
                            <span className="icon-bar"></span>{" "}
                            <span className="icon-bar"></span>{" "}
                            <span className="icon-bar"></span>{" "}
                        </button>
                        <a href="/">
                            <img
                                alt=""
                                src="assets/event/logo3.png" style={{ height: '60px', width: 'auto' }}
                            />
                        </a>
                    </div>

                    <div
                        className="collapse navbar-collapse"
                        id="bs-example-navbar-collapse-1"
                    >
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to="/" className="page-scroll" onClick={scrollToTop}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/about" className="page-scroll" onClick={scrollToTop}>
                                    About
                                </Link>
                            </li>
                            <li>
                                <Link to="/gallery" className="page-scroll" onClick={scrollToTop}>
                                    Gallery
                                </Link>
                            </li>
                            <li>
                                <Link to="/packages" onClick={toggleSubmenu} className="page-scroll">
                                    Packages
                                </Link>
                                <ul className={`submenu ${isSubmenuOpen ? 'open' : ''}`}>
                                    <li>
                                        <Link to="/birthday" onClick={closeSubmenu}>Birthday</Link>
                                    </li>
                                    <li>
                                        <Link to="/wedding" onClick={closeSubmenu}>Wedding</Link>
                                    </li>
                                    <li>
                                        <Link to="/babyshower" onClick={closeSubmenu}>Baby Shower</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/Fun&Entertainment" onClick={closeSubmenu}>Fun & Entertainment</Link>
                                    </li> */}
                                </ul>
                            </li>
                            <li>
                                <Link to="/contact" className="page-scroll" onClick={scrollToTop}>
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navigation;
