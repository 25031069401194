import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './pages/index';
import About from './pages/about';
import Gallery from './pages/gallery';
import Contact from './pages/contact';
import Packages from './pages/packages';
import Balloon from './pages/balloon';
import Wedding from './pages/wedding';
import BabyShower from './pages/babyshower';
import Fun from './pages/fun';
import DetailPage from './pages/DetailPage';
import DetailPageWedding from './pages/DetailPageWedding';
import DetailPageBabyShower from './pages/DetailsPageBabyshower';
import DetailPageFun from './pages/DetailPageFun';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
        <Route path="/detailsfun&Entertainment" element={<DetailPageFun />} />
          <Route path="/detailsBabyshower" element={<DetailPageBabyShower/>} />
          <Route path="/detailsWedding" element={<DetailPageWedding />} />
          <Route path="/details" element={<DetailPage />} />
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/packages" element={<Packages />} />
          <Route path="/birthday" element={<Balloon />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Fun&Entertainment" element={<Fun />} />
          <Route path="/wedding" element={<Wedding />} />
          <Route path="/babyshower" element={<BabyShower />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
